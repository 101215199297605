import React, { FC } from "react";
import { useLocation } from "react-router-dom";


interface Props {
  dayOfMonth: number;
  date?: Date | undefined;
  className?: string; // Add the className prop
}

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth, className }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  return (
    <>
    {isHomePage ? (
         <div className={className}>
         <span className="small-radius react-datepicker__day_span react-datepicker__day--today  ">
           {dayOfMonth}
         </span>
       </div>
    ) : (
      <div className={className}>
      <span className="react-datepicker__day_span react-datepicker__day--today  ">
        {dayOfMonth}
      </span>
    </div>
    )}
    
    </>
 
  );
};

export default DatePickerCustomDay;
