import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import React, { useState, FC, useEffect, useContext } from "react";
import axios from "axios";
import { API_URL } from "../../api/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "context/userContext";
import { useNavigate } from "react-router-dom";


export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false);
  //like feature
  const authContext = useContext(AuthContext);
  //filter page funct
  const {
    typevalues,
    setTypevalues,
    sortValues,
    setSortValues,
    rangePrices,
    setRangePrices,
    beds,
    setBeds,
    bedrooms,
    setBedrooms,
    bathrooms,
    setBathrooms,
    amenitiesValues,
    setAmenitiesValues,
    houseRulesValues,
    setHouseRulesValues,
  } = authContext;

  //searchbar funct
  const {
    searchLocationValue,
    setSearchLocationValue,
    startDate,
    endDate,
    handleDateChange,
    guests,
    setGuests,
    setInfo,
    info,
  } = authContext;

  const navigate = useNavigate();


  
  const getPropertyData = async (filter_type: String, sortBy?: String) => {
    try {
   
      setLoading(true);
      const response = await axios.post(`${API_URL}/property/get-property`, {
        type: filter_type === "type" ? [] : typevalues,
        sort: filter_type === "sort" ? sortBy : sortValues,
        min: filter_type === "price" ? 0 : rangePrices.min,
        max: filter_type === "price" ? 0 : rangePrices.max,
        beds: filter_type === "rooms" ? 0 : beds > 0 ? beds : undefined,
        bedrooms:
          filter_type === "rooms" ? 0 : bedrooms > 0 ? bedrooms : undefined,
        bathrooms:
          filter_type === "rooms" ? 0 : bathrooms > 0 ? bathrooms : undefined,
        amenities:
          filter_type === "more_filters"
            ? []
            : amenitiesValues.length > 0
            ? amenitiesValues
            : undefined,
        houseRulesValues:
          filter_type === "more_filters"
            ? []
            : houseRulesValues.length > 0
            ? houseRulesValues
            : undefined,
        locationSearch: searchLocationValue,
        startDate:startDate,
        endDate:endDate,
        guestsSearch: guests,
      });
      if (response.data.error === false) {
        setInfo(response.data.propertydata);
        navigate("/search-result");

      }
    } catch (err) {
      toast.error("Error while fetching properties data");
      console.error("Error while fetching properties data", err);
    } finally{
      setLoading(false);
    }
  };
  

  useEffect(() => {
    getPropertyData("");
  }, []);


 
  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className} gap-10 z-[0]`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Ezstays | Homestays, PGs</title>
      </Helmet>
      {/* <BgGlassmorphism /> */}

      <div className="container relative overflow-hidden">
        {/* <h2
          className="font-[600] text-black text-2xl sm:text-3xl md:text-4xl lg:text-6xl xl:text-[50px] !leading-[115%] justify-center m-auto text-center p-6"
          style={{
            letterSpacing: "-2px",
            wordSpacing: "2px",
          }}
        >
          Travel anywhere, <br /> your getaway, your way!
        </h2> */}
        {/* HERO SECTION */}
        {/* <SectionHeroArchivePage
          getPropertyFunc={getPropertyData}
          searchLocationValue={searchLocationValue}
          setSearchLocationValue={setSearchLocationValue}
          startDate={startDate}
          endDate={endDate}
          handleDateChange={handleDateChange}
          guests={guests}
          setGuests={setGuests}
          currentPage="Stays"
          currentTab="Stays"
          className="pt-4 pb-12 lg:pb-14 lg:pt-8"
        /> */}
        {/* PROPERTY SECTION */}
        <SectionGridFilterCard
          getPropertyFunc={getPropertyData}
          allPropertyData={info}
          typeFilter={typevalues.length > 0 ? typevalues : []}
          setTypefilter={setTypevalues}
          sortFilter={sortValues ? sortValues: ""}
          setSortFilter={setSortValues}
          rangePrices={rangePrices}
          setRangePrices={setRangePrices}
          beds={beds}
          setBeds={setBeds}
          bedrooms={bedrooms}
          setBedrooms={setBedrooms}
          bathrooms={bathrooms}
          setBathrooms={setBathrooms}
          amenitiesValues={amenitiesValues}
          setAmenitiesValues={setAmenitiesValues}
          houseRulesValues={houseRulesValues}
          setHouseRulesValues={setHouseRulesValues}
          className="pb-24 lg:pb-28"
          //feat like
          loading={loading}
          info={info}
        />
      </div>
    </div>
  );
};

export default ListingStayPage;
