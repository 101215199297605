import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import { Dialog, Tab, Transition } from "@headlessui/react";
import React, {
  useState,
  FC,
  useEffect,
  useContext,
  Fragment,
  useRef,
} from "react";
import axios from "axios";
import { API_URL } from "../../api/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "context/userContext";
import SectionGridFilterProperties from "./SectionGridFilterProperties";

export interface ListingStayPageProps {
  className?: string;
  autoFocus?: boolean;
}

const ListingStayPage: FC<ListingStayPageProps> = ({
  className = "",
  autoFocus,
}) => {
  const [loading, setLoading] = useState(false);
  const [filterProperties, setFilterProperties] = useState([]);
  const [showPopover, setShowPopover] = useState(autoFocus);

  const [activeTab, setActiveTab] = useState("Star_Properties");
  const authContext = useContext(AuthContext);

  const modalRef = useRef<HTMLDivElement>(null);
  //filter page funct
  const {
    showSearchModal,
    setShowSearchModal,
    showHeight,
    typevalues,
    sortValues,
    rangePrices,
    beds,
    bedrooms,
    bathrooms,
    amenitiesValues,
    houseRulesValues,
  } = authContext;

  //searchbar funct
  const {
    guests: guestSearch,
    searchLocationValue,
    setSearchLocationValue,
    startDate,
    endDate,
    handleDateChange,
    guests,
    setGuests,
    setInfo,
    errorMsg,
    setErrorMsg,
  } = authContext;

  const getPropertyData = async (filter_type: String) => {
    try {
      const response = await axios.post(`${API_URL}/property/get-property`, {
        type: filter_type === "type" ? [] : typevalues,
        sort: filter_type === "sort" ? [] : sortValues,
        min: filter_type === "price" ? 0 : rangePrices.min,
        max: filter_type === "price" ? 0 : rangePrices.max,
        beds: filter_type === "rooms" ? 0 : beds > 0 ? beds : undefined,
        bedrooms:
          filter_type === "rooms" ? 0 : bedrooms > 0 ? bedrooms : undefined,
        bathrooms:
          filter_type === "rooms" ? 0 : bathrooms > 0 ? bathrooms : undefined,
        amenities:
          filter_type === "more_filters"
            ? []
            : amenitiesValues.length > 0
            ? amenitiesValues
            : undefined,
        houseRulesValues:
          filter_type === "more_filters"
            ? []
            : houseRulesValues.length > 0
            ? houseRulesValues
            : undefined,
        locationSearch: searchLocationValue,
        guestsSearch: guests,
        startDate,
        endDate,
      });

      if (response.data.error === false) {
        setInfo(response.data.propertydata);
      }
    } catch (err) {
      toast.error("Error while fetching properties data");
      console.error("Error while fetching properties data", err);
    }
  };

  // GET STAR PROPERTIES
 
  const getPropsBasedOnActiveTab = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}/property/get-props-on-active-tab?pick=${activeTab}`
      );
      if (response.data.error === false && response.data.propertyData) {
        setFilterProperties(response.data.propertyData);
      } else {
        console.error("Properties not found or empty");
      }
    } catch (error) {
      toast.error("Error while fetching properties");
      console.error("Error while fetching properties", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPropsBasedOnActiveTab();
  }, [activeTab]);

  useEffect(() => {
    // Event listener to detect clicks outside the modal
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowSearchModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, setShowSearchModal]);

  // RENDER SEARCH BAR
  const renderSearchBar = () => {
    return (
      <div
        style={{
          backgroundColor: showSearchModal
            ? "rgba(0, 0, 0, 0.5)"
            : "transparent",
          opacity: showSearchModal ? 0.9 : 1,
          position: "fixed",
          top: 90,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 50,
          display: showSearchModal ? "block" : "none",
        }}
      >
        <div className={`HeroSearchForm2Mobile`}>
          <Transition appear show={showSearchModal} as={Fragment}>
            <Dialog
              as="div"
              className="HeroSearchFormMobile__Dialog relative z-max"
              onClose={() => setShowSearchModal(false)}
            >
              <div
                className="fixed inset-0 bg-dark-100 dark:bg-neutral-900 "
                onClick={() => setShowSearchModal(false)}
              >
                <div className="flex">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out transition-transform"
                    enterFrom="opacity-0 scale-75"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in transition-transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-75"
                  >
                    <Dialog.Panel
                      className="relative h-20vh flex-1 flex flex-col justify-between"
                      ref={modalRef}
                      style={{
                        minHeight: showHeight ? "75vh" : undefined,
                        backgroundColor: "transparent",
                      }}
                    >
                      {showSearchModal && (
                        <Tab.Group manual>
                          <div
                            className="flex-1 mx-4
                           sm:px-4 flex"
                            style={{ marginLeft: "0px", height: "100vh" }}
                          >
                            <Tab.Panels
                              className="flex-1 overflow-y-auto hiddenScrollbar pt-4 container"
                              style={{ paddingTop: "7rem" }}
                              onClick={(e) => {
                                !showPopover && setShowSearchModal(false);
                                e.stopPropagation();
                              }}
                            >
                              <Tab.Panel>
                                <div className="transition-opacity animate-[myblur_0.4s_ease]">
                                  {showSearchModal && (
                                    <SectionHeroArchivePage
                                      getPropertyFunc={getPropertyData}
                                      searchLocationValue={searchLocationValue}
                                      setSearchLocationValue={
                                        setSearchLocationValue
                                      }
                                      setShowSearchModal={setShowSearchModal}
                                      startDate={startDate}
                                      endDate={endDate}
                                      handleDateChange={handleDateChange}
                                      errorMsg={errorMsg}
                                      setErrorMsg={setErrorMsg}
                                      guests={guestSearch}
                                      setGuests={setGuests}
                                      currentPage="Stays"
                                      currentTab="Stays"
                                      className="lg:pb-1"
                                    />
                                  )}
                                </div>
                              </Tab.Panel>
                            </Tab.Panels>
                          </div>
                        </Tab.Group>
                      )}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className} gap-10`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>EZstays | HomeStays, PGs</title>
      </Helmet>
      {/* <BgGlassmorphism /> */}

      <div className="container ">
        {/* <h2
          className="lg:font-[600] md:font-[500] font-[500] text-black text-2xl sm:text-3xl md:text-4xl lg:text-6xl xl:text-[50px] !leading-[115%] justify-center m-auto text-center lg:p-6 py-6"
          style={{
            letterSpacing: "-2px",
            wordSpacing: "2px",
          }}
        >
          Travel anywhere, <br /> your getaway, your way!
        </h2> */}
        {/* HERO SECTION */}
        {/* <SectionHeroArchivePage
          searchLocationValue={searchLocationValue}
          setSearchLocationValue={setSearchLocationValue}
          startDate={startDate}
          endDate={endDate}
          handleDateChange={handleDateChange}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          currentPage="Stays"
          currentTab="Stays"
          className="lg:pb-14 lg:pt-8"
        /> */}
        {renderSearchBar()}

        {/* FILTER PROPERTIES BASED ON HOMESTAYS AND PGs */}
        <SectionGridFilterCard />

        {/* FILTER PROPERTIES BASED ON STAR AND EDITORS FLAG */}
        <SectionGridFilterProperties
          loading={loading}
          info={filterProperties}
          tabs={["Star Properties", "Editors Pick"]}
          onClickTab={(tab: string) =>
            setActiveTab(
              tab === "Star Properties"
                ? "Star_Properties"
                : "Editors_Properties"
            )
          }
        />
      </div>
    </div>
  );
};

export default ListingStayPage;
