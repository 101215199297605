import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import moment from "moment";
import { useState } from "react";

interface CommentListingDataType {
  userId?: { image: any; name: any }; // Make userId optional
  createdAt: string;
  review: string;
  rating: number;
  maxLength:number;
}

export interface CommentListingProps {
  className?: string;
  data?: CommentListingDataType;
  hasListingTitle?: boolean;
}

const CommentListing: FC<CommentListingProps> = ({
  className = "",
  data = {},
  hasListingTitle,
}) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 100; 
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const reviewText = data?.review || ""; 
  const isLongReview = reviewText.length > maxLength;

  if (!data?.userId) {
    return null;
  }


  return (
 
    <div
      className={`nc-CommentListing  ${className}`}
      data-nc-id="CommentListing"
    >
      <div className="flex items-center space-x-3">
        <Avatar
          sizeClass="lg:h-10 lg:w-10 h-8 w-8 text-lg "
          radius="rounded-full"
          userName={data?.userId?.name}
          imgUrl={data?.userId?.image}
        />
     
        <div className="inline-flex flex-col content-start ">
          
          <div className="lg:text-sm text-xs font-semibold capitalize">
            <span>{data?.userId?.name}</span>
            {hasListingTitle && (
              <>
                <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                  {` review in `}
                </span>
                <a href="/">The Lounge & Bar</a>
              </>
            )}
          </div>
          <span className=" lg:text-sm text-xs text-neutral-500 dark:text-neutral-400 mt-0.5">
            {moment(data?.createdAt).format("MMMM DD, YYYY")}{" "}
          </span>
          </div>

         
      </div>

      <div className="flex text-yellow-500 mt-3">
        {[...Array(5)].map((_, index) => (
          <StarIcon
            key={index}
            className={`lg:w-4 lg:h-4 w-2.5 h-2.5 inline-flex ${
              index < (data?.rating || 0) ? "text-yellow-500" : "text-gray-300"
            }`}
          />
        ))}
      </div>
      <div className="block mt-1 text-neutral-600 lg:text-md text-sm dark:text-neutral-300">
        {isLongReview ? (
          !isExpanded ? (
            <>
            
              {reviewText.slice(0, maxLength)}...{" "}
              <span
                className="text-black underline cursor-pointer block lg:text-sm text-xs"
                onClick={() => {
                  handleToggleExpand();
                }}
              >
                Show More
              </span>
            </>
          ) : (
            <>
              {reviewText}{" "}
              <span
                className="text-black underline cursor-pointer block lg:text-sm text-xs"
                onClick={() => setIsExpanded(false)}
              >
                Show Less
              </span>
            </>
          )
        ) : (
          reviewText
        )}
      </div>
    </div>
  );
};

export default CommentListing;
