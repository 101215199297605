import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PriceButtonSkeleton = () => {
  return (
    <div className="w-full p-3 border rounded-lg shadow-lg">
      {/* Price Skeleton */}
      <Skeleton width={150} height={30} className=" " />
      
      {/* Button Skeleton */}
      <Skeleton width={`100%`} height={35}  className='' />
    </div>
  );
};

export default PriceButtonSkeleton;
