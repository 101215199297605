import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function PropertyCardSkeleton() {
  return (
    <div className="border rounded-lg p-4 shadow-sm">
      {/* Badge and Title */}
      <div className="flex justify-between items-center mb-2">
        <Skeleton width={200} height={20} /> {/* Badge */}
        <Skeleton circle={true} height={20} width={20} /> {/* Save icon */}
      </div>

      {/* Property Title */}
      <Skeleton width="80%" height={30} />

      {/* Location */}
      <div className="flex items-center my-2">
        <Skeleton width={500} height={20} /> {/* Location */}
      </div>

      {/* Host info */}
      <div className="flex items-center my-2">
        <Skeleton circle={true} height={40} width={40} /> {/* Host image */}
        <Skeleton width={200} height={20} className="ml-2" /> {/* Host name */}
      </div>

      {/* Airbnb link */}
      <Skeleton width={130} height={30} className="my-2" />

      {/* Property info icons */}
      <div className="flex justify-between my-4">
        <Skeleton width={90} height={20} />
        <Skeleton width={90} height={20} />
        <Skeleton width={90} height={20} />
        <Skeleton width={90} height={20} />
      </div>
    </div>
  );
}
