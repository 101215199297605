import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const GallerySkeleton = () => {
  return (
    <div className="grid grid-cols-4 gap-4 ">
      {/* Large Image */}
      <div className="col-span-2 row-span-2 ">
        <Skeleton height={500} className="rounded-lg" />
      </div>
      {/* Smaller Images */}
      <div className="col-span-1">
        <Skeleton height={240} className="rounded-lg" />
      </div>
      <div className="col-span-1">
        <Skeleton height={240} className="rounded-lg" />
      </div>
      <div className="col-span-1">
        <Skeleton height={240} className="rounded-lg" />
      </div>
      <div className="col-span-1">
        <Skeleton height={240} className="rounded-lg" />
      </div>
    </div>
  );
};

export default GallerySkeleton;
