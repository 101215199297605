import { MapPinIcon } from "@heroicons/react/24/outline";
import { useState, useRef, useEffect, FC, useContext } from "react";
import ClearDataButton from "./ClearDataButton";
import { AuthContext } from "context/userContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "api/config";
import { toast } from "react-toastify";

export interface LocationInputProps {
  searchLocationValue?: any;
  setSearchLocationValue?: any;
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  startDate?: any;
  endDate?: any;
}

const LocationInput: FC<LocationInputProps> = ({
  searchLocationValue,
  setSearchLocationValue,
  autoFocus = false,
  placeHolder = "Location",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  startDate,
  endDate,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [suggestions, setSuggestions] = useState([]);
  const [cache, setCache] = useState<any>({}); // Cache object
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const authContext = useContext(AuthContext);
  const clearAllFilterValues = authContext.clearAllFilterValues;
  const setShowSearchModal = authContext.setShowSearchModal;
  const getPropertyData = authContext.getPropertyData;

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);
  const navigate = useNavigate();

  const handleChangeData = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setValue(e.currentTarget.value);
      navigate(`/search-result?q=${encodeURIComponent(searchLocationValue)}`);
      if (getPropertyData) {
        getPropertyData(searchLocationValue, startDate, endDate);
      }
      setShowSearchModal(false);
      clearAllFilterValues();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setShowPopover(false);
  };

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  // Debounced input change handler
  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setValue(value);
    setSearchLocationValue(value);
    setShowPopover(true);
    e.stopPropagation();

    if (value.length === 0) {
      setSuggestions([]);
      return;
    }

    // Check if the query is already cached
    if (cache[value]) {
      setSuggestions(cache[value]);
      setShowPopover(true);
      return;
    }

    // Debounce the API call by clearing the previous timeout
    clearTimeout(typingTimeout!);
    const newTimeout = setTimeout(async () => {
      try {
        const response = await axios.get(
          `${API_URL}/property/search-suggestions?q=${value}`
        );
        const suggestionsData = response.data;

        setSuggestions(suggestionsData);

        setCache((prevCache: any) => ({
          ...prevCache,
          [value]: suggestionsData,
        }));
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
        toast.error("Failed to fetch suggestions.");
      }
    }, 300); // Adjust the debounce delay as necessary

    setTypingTimeout(newTimeout);
  };

  const handleSelectLocation = (item: string, e: any) => {
    setSearchLocationValue(item);
    setValue(item);
    setSuggestions([]);
    setShowPopover(false);
    e.stopPropagation();
  };

  const renderSuggestions = () => {
    return (
      <>
        <div className="mt-2 px-3 ">
          {suggestions.length > 1 ? (
            <ul className="suggestions-list w-full">
              {suggestions.length > 0 &&
                suggestions.map((suggestion, index) => (
                  <>
                    <div
                      className="w-full flex"
                      key={index}
                      onClick={(e) => handleSelectLocation(suggestion, e)}
                    >
                      <div key={index} className="flex my-2 suggestions p-2 cursor-pointer w-full">
                        <span className="location-icon w-10 h-10  rounded-lg flex">
                          <MapPinIcon className="w-5 h-5 m-auto self-center items-center" />
                        </span>
                        <li
                          key={index}
                          className=" ps-3 my-auto text-start w-full"
                        >
                          {suggestion}
                        </li>
                      </div>
                    </div>
                  </>
                ))}
            </ul>
          ) : (
            <div className="mt-2 px-3 w-full">
              <p className="text-neutral-500 dark:text-neutral-400">
                No results found
              </p>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}   onChange={(e) => handleInputChange(e)}>
      <div
        onClick={(e) => {
          setShowPopover(true);
          e.stopPropagation();
          if (inputRef.current) {
            inputRef.current.focus(); // Explicitly set focus on the input field
          }
        }}
        className={` flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>

        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 pl-1 focus:outline-none focus:placeholder-neutral-300 xl:text-sm lg:text-xs font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate pb-0 pt-0`}
            placeholder={placeHolder}
            value={searchLocationValue}
            type="text"
            ref={inputRef}
            // onChange={(e) => handleInputChange(e)}
            onKeyDown={handleChangeData}
          />

          <span className="block mt-1 xl:text-sm lg:text-xs text-neutral-500 font-light pl-1 leading-none" >
            <span className="line-clamp-1 ">
              {!!searchLocationValue ? placeHolder : desc}
            </span>
          </span>
          {searchLocationValue && showPopover && (
            <ClearDataButton
              onClick={() => {
                setSearchLocationValue("");
                setValue("");
              }}
            />
          )}
        </div>
      </div>

      {showPopover && suggestions.length !== 0 && (
        <div className="absolute left-0 z-max w-full bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value && renderSuggestions()}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
