import React, { FC } from "react";
import LocationInput from "./LocationInput";
import StayDatesRangeInput from "./(stay-search-form)/StayDatesRangeInput";
import { useLocation } from "react-router-dom";

export type SearchTab = "Stays" | "Experiences" | "Cars" | "Flights";

export interface HeroSearchFormProps {
  getPropertyData?: any;
  searchLocationValue?: any;
  setSearchLocationValue?: any;
  startDate?: any;
  endDate?: any;
  handleDateChange?: any;
  errorMsg?: any;
  setErrorMsg?: any;
  guests?: any;
  setGuests?: any;
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Stays" | "Experiences" | "Cars" | "Flights";
  setShowSearchModal?: any;
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  searchLocationValue,
  setSearchLocationValue,
  startDate,
  endDate,
  handleDateChange,
  errorMsg,
  setErrorMsg,
  guests,
  setGuests,
  className = "",
  setShowSearchModal,
}) => {
  const location = useLocation();

  const isDetailPage = location.pathname.includes("/detail");
  return (
    <div
      className={`nc-HeroSearchForm w-full py-5 lg:py-0 lg:flex lg:flex-row lg:justify-between flex flex-col  lg:h-0${
        isDetailPage && setShowSearchModal ? "h-screen" : ""
      }`}
    >
      {/* <divnc-SectionHeroArchivePage lg:flex lg:flex-col hidden relative lg:pb-14 lg:pt-8
        className="lg:w-[15%]"
        onClick={(e) => {
          setShowSearchModal(false);
          e.stopPropagation();
        }}
      ></div> */}
      <form
        className="lg:w-[100%] lg:h-16 relative lg:flex lg:flex-row flex flex-col item-center lg:rounded-full rounded-3xl border shadow-xl
        dark:shadow-2xl bg-white opacity-100 dark:bg-neutral-800 xl:my-4 lg:my-3"
      >
        <LocationInput
          className="lg:flex-1"
          searchLocationValue={searchLocationValue}
          setSearchLocationValue={setSearchLocationValue}
          startDate={startDate}
          endDate={endDate}
        />
        <div className="self-center flex  border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput
          className="lg:flex-1"
          startDate={startDate}
          endDate={endDate}
          handleDateChange={handleDateChange}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          guests={guests}
          setGuests={setGuests}
          searchLocationValue={searchLocationValue}
          setSearchLocationValue={setSearchLocationValue}

        />
      </form>
      {/* <div
        className="lg:w-[15%]"
        onClick={(e) => {
          setShowSearchModal(false);
          e.stopPropagation();
        }}
      ></div> */}
    </div>
  );
};

export default HeroSearchForm;
