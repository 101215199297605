import React, { Fragment, useState, useContext } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ButtonSubmit from "./ButtonSubmit";
import { useTimeoutFn } from "react-use";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { AuthContext } from "context/userContext";
import {useLocation, useNavigate } from "react-router-dom";



const HeroSearchForm2Mobile = () => {
  const authContext = useContext(AuthContext);
  const getPropertyData = authContext.getPropertyData;
  const showModal = authContext.showModal;
  const setShowModal = authContext.setShowModal;


  const {
   
    searchLocationValue,
    setSearchLocationValue,
    startDate,
    setStartDate,
    setEndDate,
    endDate,
    handleDateChange,
    setErrorMsg,
  } = authContext;

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }
  function resetFormValues() {
    setSearchLocationValue("");
    setStartDate("");
    setEndDate("");
    getPropertyData("clear");
  }


  const { pathname } = useLocation();
  const navigate = useNavigate();

  const SearchProperty = () => {
      getPropertyData(searchLocationValue,startDate, endDate);
      const redirectToHome = pathname.includes("/detail");
      navigate(`/search-result`);
      setShowModal(false);

      // clearAllFilterValues();
      if (redirectToHome) {
        navigate("/search-result");
        // clearAllFilterValues();
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    
 
  };

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        className=" relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 lg:pr-11 rounded-full shadow-lg"
      >
        <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />

        <div className="ml-3 flex-1 text-left overflow-hidden">
          <span className="block font-medium lg:text-sm text-xs">Where to?</span>
          <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 ">
            <span className="line-clamp-1 ">Anywhere</span>
          </div>
        </div>
      </button>
    );
  };

  return (
    <div className="HeroSearchForm2Mobile py-2 px-1 ">
      {renderButtonOpenModal()}
      
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max "
          onClose={closeModal}
        >
          <div className="fixed inset-0  dark:bg-neutral-900 ">
            <div className="flex">
              {/* <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              > */}
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between  ">
                  {showDialog && (
                    <Tab.Group manual style={{ marginLeft: "0px", height: "100vh" }}>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>
                      <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden h-screen bg-neutral-100">
                        <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              {/* <StaySearchForm /> */}
                              <HeroSearchForm 
                              searchLocationValue={searchLocationValue}
                              setSearchLocationValue = {setSearchLocationValue}
                              startDate={startDate}
                              endDate={endDate}
                              handleDateChange={handleDateChange}
                              />
                            </div>
                          </Tab.Panel>
                        </Tab.Panels>
                      </div>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between mt-[-100px]">
                         {/* <button
                          type="button"
                          className="underline font-semibold flex-shrink-0"
                          onClick={() => {
                            setShowDialog(false);
                            resetFormValues();
                            resetIsShowingDialog();
                          }}
                        >
                          Clear all
                        </button> */}
                        <ButtonSubmit
                          onClick={
                            SearchProperty
                          }
                        />
                      </div>
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              {/* </Transition.Child> */}
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2Mobile;
