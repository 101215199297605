import React, { ReactNode } from "react";
import { imageGallery as listingStayImageGallery } from "./listing-stay-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  const queryParams = new URLSearchParams(window.location.search);

  const handleCloseModalImageGallery = () => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("modal")) {
      params.delete("modal");
      const newQueryString = params.toString();
      const newUrl = `${window.location.pathname}?${newQueryString}`;
      window.history.replaceState({}, "", newUrl);
      navigate(newUrl);
    } else {
      // navigate(`/detail?propID=${propIdParam}`);
    }
    // let params = new URLSearchParams(document.location.search);
    // params.delete("modal");
    // navigate(`${thisPathname}/?${params.toString()}`);
  };
  const getImageGalleryListing = () => {
    if (thisPathname?.includes("/detail")) {
      return listingStayImageGallery;
    }
  };

  return (
    <div
      className="ListingDetailPage"
      style={{ position: "relative", zIndex: "-1" }}
    >
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />
      <div className="container ListingDetailPage__content">{children}</div>
    </div>
  );
};

export default DetailPagetLayout;
