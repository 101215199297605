import React,  { useState, useEffect, FC } from "react";
import DatePicker from "react-datepicker";
import CalendarSkeletonLoader from "./CalendarSkeletonLoader";
import PriceButtonSkeleton from "containers/ListingDetailPage/listing-stay-detail/PriceButtonSkeleton";
import axios from "axios";
import { API_URL } from "api/config";
import DatePickerCustomDay from "./DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "./DatePickerCustomHeaderTwoMonth";
import moment from "moment";



interface Booking {
  startDate: string;
  endDate: string;
}
interface SectionDateRangeProps {
  price:any;
}
const SectionDateRange: FC<SectionDateRangeProps> = ({
 price
}) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [bookedDates, setBookedDates] = useState<Booking[]>([]);
  const [highlightedDates, setHighlightedDates] = useState<Date[]>([
    new Date("2024-08-01"),
    new Date("2024-08-02"),
    new Date("2024-08-03"),
  ]);
 const [loading, setLoading] = useState(false);
  // Use a function to retrieve the propertyId from the query parameters
  const getQueryParam = (param: string): string | null => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(param);
  };

  const propIdParam = getQueryParam("propID");

  useEffect(() => {
    const fetchBookedDates = async () => {
      try {
        setLoading(true);
        if (propIdParam) {
          const response = await axios.get(
            `${API_URL}/property/property-bookings?propID=${propIdParam}`
          );
          const bookings: Booking[] = response.data.bookings;

          if (bookings.length === 0) {
            return;
          } else {
            // Convert booked date ranges into individual date objects
            const bookedDateObjects: Date[] = bookings.flatMap((booking) => {
              // return dates;
              const start = moment.utc(booking.startDate).startOf("day");
              const end = moment.utc(booking.endDate).startOf("day");
              const dateArray = [];

              let currentDate = start.clone();

              while (currentDate.isSameOrBefore(end)) {
                dateArray.push(currentDate.toDate());
                currentDate = currentDate.clone().add(1, "day");
              }

              return dateArray;
            });

            setBookedDates(bookings);
            setHighlightedDates(bookedDateObjects);
          }
        } else {
          console.error("Property ID not found in query parameters");
        }
      } catch (error) {
        console.error("Error fetching booked dates:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookedDates();
  }, [propIdParam]);

  const onChangeDate = (dates: [Date | null, Date | null] | null) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);

      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  // Function to disable past dates and booked dates
  const isDateSelectable = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds

    // Check if the date is in the past
    if (date < today) {
      return false;
    }

    // Check if the date is highlighted
    for (const highlightedDate of highlightedDates) {
      if (highlightedDate.getTime() === date.getTime()) {
        return true;
      }
    }

    return false;
  };

  const getDayClassName = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    if (date < today) {
      return "past-date";
    }

    const isStart = bookedDates.some(
      (booking) =>
        moment(booking.startDate).format("YYYY-MM-DD") === formattedDate
    );
    const isEnd = bookedDates.some(
      (booking) =>
        moment(booking.endDate).format("YYYY-MM-DD") === formattedDate
    );
    const isInRange = bookedDates.some(
      (booking) =>
        moment(booking.startDate).isBefore(formattedDate) &&
        moment(booking.endDate).isAfter(formattedDate)
    );

    if (isStart && isEnd) {
      // If the start and end dates are the same (single-day booking)
      return "react-datepicker__day--start-end";
    } else if (isStart) {
      return "react-datepicker__day--start";
    } else if (isEnd) {
      return "react-datepicker__day--end";
    } else if (isInRange) {
      return "react-datepicker__day--in-range booking-range";
    } else {
      return "available-dates";
    }

  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        {loading ? (<PriceButtonSkeleton/>) : (
        <div>
        {/* <h2 className="text-2xl lg:font-semibold font-[500]">Availability</h2> */}
        {/* PRICE  */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold self-center">
            <div
              className={`
                
                  "2xl:p-4 lg:p-1 p-1 flex justify-between items-center space-x-2 rounded-lg"
              `}
            >
              <span
                className="lg:font-semibold lg:text-3xl font-[500] text-xl"
                style={{ fontFamily: "Roboto" }}
              >
                {Number(price).toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                })}
              </span>
              <span className="lg:font-semibold lg:text-3xl font-[500] text-xl">
                /night
              </span>
            </div>
          </span>
        </div>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400 text-sm lg:text-[16px]">
          Prices may increase on weekends or holidays
        </span>
      </div>
        )}

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

{ loading ? (
  <CalendarSkeletonLoader/>
) : (
  <div className="react-datepicker--two-columns">
  <DatePicker
    onChange={onChangeDate}
    startDate={startDate}
    endDate={endDate}
    selectsRange
    monthsShown={1}
    showPopperArrow={false}
    inline
    highlightDates={highlightedDates}
    filterDate={isDateSelectable}
    dayClassName={getDayClassName}
    renderCustomHeader={(p) => (
      <DatePickerCustomHeaderTwoMonth {...p} />
    )}
    renderDayContents={(day: number, date: Date) => (
      <DatePickerCustomDay dayOfMonth={day} date={date} />
    )}
  />
  {/* RENDER COLOR LABEL */}
  <div className="flex space-x-6 mt-5 items-baseline	">
    <div className="flex items-center space-x-2">
      <span
        className="inline-block w-2 h-2 rounded-full"
        style={{ backgroundColor: "#acaaaa" }}
      ></span>
      <span className="lg:text-[16px] text-xs">Booked Dates</span>
    </div>
    <div className="flex items-center space-x-2">
      <span className="inline-block w-2 h-2 bg-black rounded-full"></span>
      <span className="lg:text-[16px] text-xs">Available Dates</span>
    </div>
  </div>
</div>
)}
       
      </div>
    );
  };

  return renderSectionCheckIndate();
};

export default SectionDateRange;
