import React, { FC } from "react";
import twFocusClass from "utils/twFocusClass";
import { AuthContext } from "context/userContext";
import { useNavigate } from "react-router-dom";

import { useContext } from "react";

export interface NextPrevProps {
  className?: string;
  currentPage?: number;
  totalPage?: number;
  btnClassName?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  onlyNext?: boolean;
  onlyPrev?: boolean;
  propertyId?: string;
}

const NextPrev: FC<NextPrevProps> = ({
  className = "",
  onClickNext = () => {},
  onClickPrev = () => {},
  btnClassName = "w-10 h-10",
  onlyNext = false,
  onlyPrev = false,
  propertyId,
}) => {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const { getOnePropertyDetails } = authContext;

  const HandleDivClick = () => {

    navigate(`/detail?propID=${propertyId}`);
    getOnePropertyDetails(propertyId);
  };

  return (
    <div
      className={`nc-NextPrev relative flex justify-between items-center text-neutral-900 dark:text-neutral-300 cursor-pointer ${className}`}
      data-nc-id="NextPrev"
      data-glide-el="controls"
      onClick={HandleDivClick}
    >
      {!onlyNext && (
        <button
          className={`${btnClassName} ${
            !onlyPrev ? "mr-[6px]" : ""
          } bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 ${twFocusClass()}`}
          onClick={(e) => {
            onClickPrev();
            e.stopPropagation();
          }}
          title="Prev"
          data-glide-dir="<"
        >
          <i className="las la-angle-left"></i>
        </button>
      )}

      {!onlyPrev && (
        <button
          className={`${btnClassName} bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 ${twFocusClass()}`}
          onClick={(e) => {
            onClickNext();
            e.stopPropagation();
          }}
          title="Next"
          data-glide-dir=">"
        >
          <i className="las la-angle-right"></i>
        </button>
      )}
    </div>
  );
};

export default NextPrev;
