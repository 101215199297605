import { avatarColors } from "contains/contants";
import React, { FC } from "react";
import default_Img from "../../images/avatars/user_icon.png";


export interface AvatarProps {
  containerClassName?: string;
  sizeClass?: string;
  radius?: string;
  imgUrl?: string;
  userName?: string;
  hasChecked?: boolean;
  hasCheckedClass?: string;
}

const Avatar: FC<AvatarProps> = ({
  containerClassName = "ring-1 ring-white dark:ring-neutral-900",
  sizeClass = "lg:h-6 lg:w-6 h-2 w-2 lg:text-sm text-xs",
  radius = "rounded-full",
  imgUrl,
  userName,
  hasChecked,
  hasCheckedClass = "w-4 h-4 -top-0.5 -right-0.5",
}) => {
  const displayImg = imgUrl || "";
  const name = userName ? userName : "";
  const _setBgColor = (name: string) => {
    const backgroundIndex = Math.floor(
      name.charCodeAt(0) % avatarColors.length
    );
    return avatarColors[backgroundIndex];
  };
  return (
    // <div
    //   className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase lg:font-semibold font-[300] shadow-inner ${radius} ${sizeClass} ${containerClassName}`}
    //   style={{ backgroundColor: displayImg ? ""  : _setBgColor(name) }}
    // >
    //   {displayImg ? (
    //     <img
    //       className={`absolute inset-0 w-full h-full object-cover ${radius}`}
    //       src={displayImg}
    //       alt={name}
    //     />
        
    //   ) : (
    //     <img
    //     className={`absolute inset-0 w-full h-full object-cover ${radius}`}
    //     src={default_Img}
    //     alt={name}
    //   />
      
      
    //   )}
    //   <span className="wil-avatar__name">{name[0]}</span>

    //   {hasChecked && (
    //     <span
    //       className={` bg-teal-500 rounded-full text-white text-xs flex items-center justify-center absolute  ${hasCheckedClass}`}
    //     >
    //       <i className="las la-check"></i>
    //     </span>
    //   )}
    // </div>
    <div
    className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner ${radius} ${sizeClass} ${containerClassName}`}
    style={{ backgroundColor: displayImg ? undefined : _setBgColor(name) }}
  >
    {displayImg && (
      <img
        className={`absolute inset-0 w-full h-full object-cover ${radius}`}
        src={displayImg}
        alt={name}
      />
    )}
    <span className="wil-avatar__name">{name[0]}</span>

    {hasChecked && (
      <span
        className={` bg-teal-500 rounded-full text-white text-xs flex items-center justify-center absolute  ${hasCheckedClass}`}
      >
        <i className="las la-check"></i>
      </span>
    )}
  </div>
  );
};

export default Avatar;
