import React, { Fragment, FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import SearchIcon from "../SearchIcon";
import { useState, useEffect } from "react";

export interface StayDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  startDate?: any;
  endDate?: any;
  handleDateChange: (dates: any) => void;
  errorMsg?: any;
  setErrorMsg?: any;
  guests?: any;
  setGuests?: any;
  searchLocationValue?: any;
  setSearchLocationValue:any; 

}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[ nc-hero-field-padding ]",
  startDate,
  endDate,
  handleDateChange,
  errorMsg,
  guests,
  setGuests,
  searchLocationValue,
  setSearchLocationValue
}) => {
  // Function to check if a date is in the past
  const isPastDate = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-sm lg:text-xs font-semibold">
            {startDate instanceof Date
              ? startDate.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                })
              : "Add dates"}
            {endDate instanceof Date
              ? " - " +
                endDate.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 xl:text-sm lg:text-xs text-neutral-500 dark:text-neutral-300 leading-none line-clamp-1 ">
            {"Check in - Check out"}
          </span>
        </div>
      </>
    );
  };
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const parentDiv = document.querySelector(".StayDatesRangeInput");
    if (!parentDiv) return; // Check if parentDiv exists

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    parentDiv.addEventListener("focus", handleFocus, true);
    parentDiv.addEventListener("blur", handleBlur, true);

    return () => {
      parentDiv.removeEventListener("focus", handleFocus);
      parentDiv.removeEventListener("blur", handleBlur);
    };
  }, []);

  return (
    <div
      className={`StayDatesRangeInput z-10 relative flex justify-center items-center ${
        isFocused ? "nc-hero-field-focused" : ""
      } ${className}`}
    >
      <Popover className="flex-grow">
        {({ open }) => {
          // Set isFocused to false when Popover is closed
          setIsFocused(open ? true : false);

          return (
            <>
              <Popover.Button
                className={`flex-1 w-full z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none pr-0`}
                onClickCapture={() => document.querySelector("html")?.click()}
              >
                {renderInput()}
                {startDate && open && (
                  <ClearDataButton
                    onClick={() => handleDateChange([null, null])}
                  />
                )}
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute start-1/2 end-2/3 z-10 mt-3 top-full w-full -translate-x-1/2 transform  sm:px-0 xl:max-w-xl">
                  <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 lg:p-8 p-6">
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      showPopperArrow={false}
                      inline
                      monthsShown={1}
                      renderCustomHeader={(p) => (
                        <DatePickerCustomHeaderTwoMonth {...p} />
                      )}
                      renderDayContents={(day, date) => (
                        <DatePickerCustomDay dayOfMonth={day} date={date} />
                      )}
                      dayClassName={(date) =>
                        isPastDate(date) ? "past-date" : ""
                      }
                    />
                    {errorMsg && (
                      <div
                        className="error-message text-xs ms-3"
                        style={{ color: "red", marginTop: "5px" }}
                      >
                        {errorMsg}
                      </div>
                    )}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
      <SearchIcon
        className=""
        searchLocationValue={searchLocationValue}
        startDate={startDate}
        endDate={endDate}
        setSearchLocationValue={setSearchLocationValue}

      />
    </div>
  );
};

export default StayDatesRangeInput;
