import React, {
  useState,
  useEffect,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import axios from "axios";
import { API_URL } from "../api/config";
import { toast } from "react-toastify";

interface UserData {
  _id: string;
  name: string;
  email: string;
  dateOfBirth: any;
  phoneNumber: string;
  image: string;
  wallet_balance: any;
  otp:string;
}

interface AuthContextProps {
  getPropertyData: any;
  setFavPropData: any;
  favPropData: any;
  userData: UserData;
  getOnePropertyDetails: any;
  getPropertyRatings: any;
  totalOwnerProperty: any;
  setTotalOwnerProperty: any;
  calculateAverageRating: any;
  onLogout: () => void;
  getAdminData: () => Promise<void>;
  getFavouriteProps: () => Promise<void>;
  addtoSavedList: (idd: any) => Promise<void>;
  delFromSavedList: (idd: any) => Promise<void>;
  reloadUserData: () => void;
  loading: any;
  setLoading: any;
  searchLocationValue: string;
  setSearchLocationValue: React.Dispatch<React.SetStateAction<string>>;
  startDate: String | "";
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: String | "";
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  errorMsg: String | "";
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  handleDateChange: (dates: any, e?: any) => void;
  guests: number;
  setGuests: React.Dispatch<React.SetStateAction<number>>;
  info: any[]; // Include the info state
  setInfo: React.Dispatch<React.SetStateAction<any[]>>;
  guestAdultsInputValue: number; // Add guestAdultsInputValue state
  setGuestAdultsInputValue: React.Dispatch<React.SetStateAction<number>>;
  guestChildrenInputValue: number; // Add guestChildrenInputValue state
  setGuestChildrenInputValue: React.Dispatch<React.SetStateAction<number>>;
  guestInfantsInputValue: number; // Add guestInfantsInputValue state
  setGuestInfantsInputValue: React.Dispatch<React.SetStateAction<number>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showSearchModal: boolean;
  setShowSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
  showHeight: boolean;
  setShowHeight: React.Dispatch<React.SetStateAction<boolean>>;
  showModalPh: boolean;
  setShowModalPh: React.Dispatch<React.SetStateAction<boolean>>;
  // Add the new state values here:
  typevalues: string[];
  sortValues: string;
  setSortValues: React.Dispatch<React.SetStateAction<string[]>>;
  setTypevalues: React.Dispatch<React.SetStateAction<string[]>>;
  rangePrices: { min: number; max: number };
  setRangePrices: React.Dispatch<
    React.SetStateAction<{ min: number; max: number }>
  >;
  beds: number;
  setBeds: React.Dispatch<React.SetStateAction<number>>;
  bedrooms: number;
  setBedrooms: React.Dispatch<React.SetStateAction<number>>;
  bathrooms: number;
  setBathrooms: React.Dispatch<React.SetStateAction<number>>;
  amenitiesValues: string[];
  setAmenitiesValues: React.Dispatch<React.SetStateAction<string[]>>;
  houseRulesValues: string[];
  setHouseRulesValues: React.Dispatch<React.SetStateAction<string[]>>;
  clearAllFilterValues: any;
  //rating and reviews
  allReview: any[];
  setAllReview: Dispatch<SetStateAction<any[]>>;
  reviewValue: string;
  setReviewValue: Dispatch<SetStateAction<string>>;
  ratingValue: number;
  setRatingValue: Dispatch<SetStateAction<number>>;
  avgRating: number;
  setAvgRating: Dispatch<SetStateAction<number>>;
}

const initialState: AuthContextProps = {
  userData: {
    _id: "",
    name: "",
    email: "",
    dateOfBirth: "",
    phoneNumber: "",
    image: "",
    wallet_balance: "",
    otp:"",
  },
  onLogout: () => {},
  getAdminData: async () => {},
  getFavouriteProps: async () => {},
  reloadUserData: () => {},
  loading: false,
  setLoading: () => {},
  favPropData: [],
  setFavPropData: async () => {},
  addtoSavedList: async (idd: any) => {},
  delFromSavedList: async (idd: any) => {},
  searchLocationValue: "",
  setSearchLocationValue: () => {},
  startDate: "",
  setStartDate: () => {},
  endDate: "",
  setEndDate: () => {},
  handleDateChange: (dates: any) => {},
  errorMsg: "",
  setErrorMsg: () => {},
  guests: 0,
  setGuests: () => {},
  info: [], // Initialize info state
  setInfo: () => {},
  getPropertyData: () => {},
  getOnePropertyDetails: () => {},
  getPropertyRatings: () => {},
  totalOwnerProperty: 0,
  setTotalOwnerProperty: () => {},
  calculateAverageRating: () => {},
  guestAdultsInputValue: 0, // Initialize guestAdultsInputValue
  setGuestAdultsInputValue: () => {},
  guestChildrenInputValue: 0, // Initialize guestChildrenInputValue
  setGuestChildrenInputValue: () => {},
  guestInfantsInputValue: 0, // Initialize guestInfantsInputValue
  setGuestInfantsInputValue: () => {},
  showModal: false,
  setShowModal: () => {},
  //searchbar detail page
  showSearchModal: false,
  setShowSearchModal: () => {},
  showHeight: false,
  setShowHeight: () => {},
  showModalPh: false,
  setShowModalPh: () => {},
  // Add the new state values here:
  typevalues: [],
  setTypevalues: () => {},
  sortValues: "",
  setSortValues: () => {},
  rangePrices: { min: 0, max: 0 },
  setRangePrices: () => {},
  beds: 0,
  setBeds: () => {},
  bedrooms: 0,
  setBedrooms: () => {},
  bathrooms: 0,
  setBathrooms: () => {},
  amenitiesValues: [],
  setAmenitiesValues: () => {},
  houseRulesValues: [],
  setHouseRulesValues: () => {},
  clearAllFilterValues: () => {},
  // Other existing properties...
  allReview: [],
  setAllReview: () => {},
  reviewValue: "",
  setReviewValue: () => {},
  ratingValue: 5,
  setRatingValue: () => {},
  avgRating: 0,
  setAvgRating: () => {},
};

const AuthContext = createContext<AuthContextProps>(initialState);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserData>({
    _id: "",
    name: "",
    email: "",
    dateOfBirth: "",
    phoneNumber: "",
    image: "",
    wallet_balance: 0,
  } as any);
  //rating and reviews
  const [allReview, setAllReview] = useState<any>([]);
  const [reviewValue, setReviewValue] = useState("");
  const [ratingValue, setRatingValue] = useState(5);
  const [avgRating, setAvgRating] = useState(0);
  //favourites and loading
  const [favPropData, setFavPropData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  //filter page funct
  const [typevalues, setTypevalues] = useState<any>([]);
  const [sortValues, setSortValues] = useState<any>("");

  const [rangePrices, setRangePrices] = useState({ min: 0, max: 0 });
  const [beds, setBeds] = useState(0);
  const [bedrooms, setBedrooms] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [amenitiesValues, setAmenitiesValues] = useState<string[]>([]);
  const [houseRulesValues, setHouseRulesValues] = useState<string[]>([]);
  //search bar funct
  const [info, setInfo] = useState<any[]>([]);
  const [searchLocationValue, setSearchLocationValue] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [guests, setGuests] = useState(0);
  const [totalOwnerProperty, setTotalOwnerProperty] = useState(0);
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(0);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);
  // const totalGuests = guestAdultsInputValue + guestChildrenInputValue;
  const [showModal, setShowModal] = useState(false);
  const [showHeight, setShowHeight] = useState(false);

  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showModalPh, setShowModalPh] = useState(false);

  const onLogout = () => {
    console.log("logged out");

    setUserData(initialState.userData);
    localStorage.clear();
    sessionStorage.clear();
    // window.location.href = "/login";
  };
  const handleDateChange = (dates: any, e?: any) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    const startDate = dates[0];
    const endDate = dates[1];
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (startDate && startDate < today) {
      setErrorMsg("Please select a date that is not in the past.");
      setStartDate("");
      setEndDate("");
      return;
    }
    if (endDate && endDate < today) {
      setErrorMsg("Please select an date that is not in the past.");
      setStartDate("");
      setEndDate("");
      return;
    }

    setStartDate(startDate);
    setErrorMsg("");
    setEndDate(endDate);
    setErrorMsg("");
  };

  const getAdminData = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/users/getuser`, {
        headers: {
          token: token,
        },
      });
      if (response.data.error === false) {
        const info = response.data.userdata;

        setUserData({
          _id: info._id,
          name: info.name,
          email: info.email,
          dateOfBirth: info.dateOfBirth,
          phoneNumber: info.phoneNumber,
          image: info.image,
          wallet_balance: info.wallet_balance,
          otp:info.otp
        });
      }
      setLoading(false);
    } catch (err) {
      console.error("error while fetching userInfo", err);
      // toast.error(
      //   <>
      //     Last session expired!
      //     <br />
      //     Please login again
      //   </>
      // );
      // onLogout();
      setLoading(false);
    }
  };
  const getFavouriteProps = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${API_URL}/users/get-fav`, {
        headers: {
          token: token,
        },
      });
      if (response.data.error === false) {
        setFavPropData(response.data.favourites);
      }
    } catch (err) {
      console.error("Error fetching saved properties", err);
    }
  };

  const addtoSavedList = async (idd: any) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${API_URL}/users/add-fav`,
        {
          propId: idd,
        },
        {
          headers: {
            token: token,
          },
        }
      );

      if (response.data.error === false) {
        toast.success(<>Property Saved!</>);
        getFavouriteProps();
      }
    } catch (err) {
      console.error("error while fetching userInfo", err);
      toast.error(`${err}`);
    }
  };
  const delFromSavedList = async (idd: any) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${API_URL}/users/del-fav`,
        {
          propId: idd,
        },
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data.error === false) {
        toast.error(<>Property removed!</>);
        getFavouriteProps();
      }
    } catch (err) {
      console.error("error removing property from favourite", err);
      toast.error(`${err}`);
    }
  };
  const clearAllFilterValues = () => {
    setTypevalues([]);
    setRangePrices({ min: 0, max: 0 });
    setBeds(0);
    setBedrooms(0);
    setBathrooms(0);
    setAmenitiesValues([]);
    setHouseRulesValues([]);
  };

  const getPropertyData = async (filter_type: String, sortBy?: String) => {
    const hasToken = localStorage.getItem("token");

    if (hasToken) {
      await getFavouriteProps();
    }
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/property/get-property`, {
        type: filter_type === "type" ? [] : typevalues,
        sort: filter_type === "sort" ? sortBy : sortValues,
        min: filter_type === "price" ? 0 : rangePrices.min,
        max: filter_type === "price" ? 0 : rangePrices.max,
        beds: filter_type === "rooms" ? 0 : beds > 0 ? beds : undefined,
        bedrooms:
          filter_type === "rooms" ? 0 : bedrooms > 0 ? bedrooms : undefined,
        bathrooms:
          filter_type === "rooms" ? 0 : bathrooms > 0 ? bathrooms : undefined,
        amenities:
          filter_type === "more_filters"
            ? []
            : amenitiesValues.length > 0
            ? amenitiesValues
            : undefined,
        houseRulesValues:
          filter_type === "more_filters"
            ? []
            : houseRulesValues.length > 0
            ? houseRulesValues
            : undefined,
        locationSearch: searchLocationValue,
        startDate: startDate,
        endDate: endDate,
        guestsSearch: guests,
      });
      if (response.data.error === false) {
        setInfo(response.data.propertydata);
      }
    } catch (err) {
      toast.error("Error while fetching properties data");
      console.error("Error while fetching properties data", err);
    } finally {
      setLoading(true);
    }
  };

  const calculateAverageRating = (allReview: any) => {
    if (allReview.length > 0) {
      const allRating = allReview.reduce((total: number, review: any) => {
        return total + review.rating;
      }, 0);
      const average = allRating / allReview.length;
      setAvgRating(Number(average.toFixed(1)));
    } else {
      setAvgRating(0);
    }
  };
  //rating allReviews
  const getPropertyRatings = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/users/get-prop-review?propId=${propIdParam}`
      );
      if (response.data.error === false) {
        setAllReview(response.data?.allReviews);
        calculateAverageRating(response.data?.allReviews);
      }
    } catch (err) {
      console.error("error while fetching userInfo", err);
      toast.error(`${err}`);
    }
  };

  const queryParams = new URLSearchParams(window.location.search);

  const propIdParam = queryParams.get("propID");

  const [show, setShow] = useState<any>(false);
  const [propertyData, setPropertyData] = useState<any>({});
  const getOnePropertyDetails = async (propertyId: any) => {
    if (!propertyId) {
      console.error("Property ID is null or undefined");
      return;
    }
    try {
      const response = await axios.get(
        `${API_URL}/property/get-propertyCard-details?id=${propertyId}&userId=${userData._id}`
      );
      if (response.data.error === false) {
        if (response.data.getTransaction) {
          setShow(true);
        }
        setPropertyData(response.data.propertyDetails);

        setTotalOwnerProperty(response.data.countTotalProperties);
        getPropertyRatings();
      }
    } catch (err) {
      toast.error("Error while fetching property details");
      console.error("Error fetching details", err);
    }
  };

  const pathnameArray = ["/login", "/signup", "/detail", "/search-result"];
  useEffect(() => {
    const hasToken = !!localStorage.getItem("token");
    const currentPathname = window?.location?.pathname;
    const isValidPathname =
      !pathnameArray.includes(currentPathname) && currentPathname !== "/";

    if (isValidPathname && !hasToken) {
      onLogout();
    }
    if (hasToken) {
      getAdminData();
    }
  }, []);
  const reloadUserData = () => {};

  const value: AuthContextProps = {
    userData,
    onLogout,
    getAdminData,
    getFavouriteProps,
    reloadUserData,
    loading,
    setLoading,
    favPropData,
    setFavPropData,
    addtoSavedList,
    delFromSavedList,
    searchLocationValue,
    setSearchLocationValue,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleDateChange,
    errorMsg,
    setErrorMsg,
    guests,
    setGuests,
    setInfo,
    info,
    getPropertyData,
    calculateAverageRating,
    getPropertyRatings,
    getOnePropertyDetails,
    totalOwnerProperty,
    setTotalOwnerProperty,
    guestAdultsInputValue,
    setGuestAdultsInputValue,
    guestChildrenInputValue,
    setGuestChildrenInputValue,
    guestInfantsInputValue,
    setGuestInfantsInputValue,
    showModal,
    setShowModal,
    showSearchModal,
    setShowSearchModal,
    showHeight,
    setShowHeight,
    showModalPh,
    setShowModalPh,
    typevalues,
    setTypevalues,
    sortValues,
    setSortValues,
    rangePrices,
    setRangePrices,
    beds,
    setBeds,
    bedrooms,
    setBedrooms,
    bathrooms,
    setBathrooms,
    amenitiesValues,
    setAmenitiesValues,
    houseRulesValues,
    setHouseRulesValues,
    clearAllFilterValues,
    allReview,
    setAllReview,
    reviewValue,
    setReviewValue,
    ratingValue,
    setRatingValue,
    avgRating,
    setAvgRating,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
