import React, { FC } from "react";
export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {
  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className} flex items-center border-b-2 b`}>
    </div>
  );
};

export default MainNav2;
function functFavourite() {
  throw new Error("Function not implemented.");
}

function setInfo(propertydata: any) {
  throw new Error("Function not implemented.");
}
