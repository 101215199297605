import "./styles/index.css";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import Modal from "./components/Modal";
import type { ListingGalleryImage } from "./utils/types";
import { useLastViewedPhoto } from "./utils/useLastViewedPhoto";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LikeSaveBtns from "components/LikeSaveBtns";
import { API_URL } from "api/config";
import axios from "axios";
import { toast } from "react-toastify";

const PHOTOS: string[] = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

export const DEMO_IMAGE: ListingGalleryImage[] = [...PHOTOS].map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);

export const getNewParam = ({
  paramName = "photoId",
  value,
}: {
  paramName?: string;
  value: string | number;
}) => {
  let params = new URLSearchParams(document.location.search);
  params.set(paramName, String(value));
  return params.toString();
};

interface Props {
  images?: ListingGalleryImage[];
  onClose?: () => void;
  isShowModal: boolean;
}

const ListingImageGallery: FC<Props> = ({
  images = DEMO_IMAGE,
  onClose,
  isShowModal,
}) => {
  let [searchParams] = useSearchParams();
  const photoId = searchParams?.get("photoId");
  const navigate = useNavigate();
  const [lastViewedPhoto, setLastViewedPhoto] = useLastViewedPhoto();

  const lastViewedPhotoRef = useRef<HTMLDivElement>(null);
  const thisPathname = useLocation().pathname;

  useEffect(() => {
    // This effect keeps track of the last viewed photo in the modal to keep the index page in sync when the user navigates back
    if (lastViewedPhoto && !photoId) {
      lastViewedPhotoRef.current?.scrollIntoView({ block: "center" });
      setLastViewedPhoto(null);
    }
  }, [photoId, lastViewedPhoto, setLastViewedPhoto]);

  const handleClose = () => {
    setLastViewedPhoto(photoId);
    let params = new URLSearchParams(document.location.search);
    params.delete("photoId");
    navigate(`${thisPathname}?${params.toString()}`);
  };

  const [gallaryImg, setGallaryImg] = useState<any>([]);
  const queryParams = new URLSearchParams(window.location.search);
  const propIdParam = queryParams.get("propID");
  const getOnePropertyDetails = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/property/get-propertyCard-details?id=${propIdParam}`
      );
      if (response.data.error === false) {
        const coverImage = response.data.propertyDetails?.cover_image;
        const galleryImages = response.data.propertyDetails?.galleryImgs || [];
        const combinedImages = [coverImage, ...galleryImages];
        setGallaryImg(combinedImages);
      }
    } catch (err) {
      toast.error("Error while fetching property details");
      console.error("Error fetching details", err);
    }
  };

  useEffect(() => {
    getOnePropertyDetails();
  }, []);
 
  const renderContent = () => {
    return (
      <div className="w-full">
        {photoId && (
          <Modal
            images={gallaryImg}
            handleClose={handleClose}
            onClose={onClose}
          />
        )}

        <div className="columns-1 gap-4 sm:columns-2 xl:columns-3">
          {gallaryImg.map((image: any, index: any) => {
            return (
              <div
                key={index}
                onClick={() => {
                  const newPathname = getNewParam({ value: index });
                  navigate(`${thisPathname}?${newPathname}`);
                }}
                ref={
                  index === Number(lastViewedPhoto) ? lastViewedPhotoRef : null
                }
                className="after:content group relative mb-5 block w-full cursor-zoom-in after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:shadow-highlight focus:outline-none"
              >
                <img
                  alt="chisfis listing gallery "
                  className="transform rounded-lg brightness-90 transition will-change-auto group-hover:brightness-110 focus:outline-none"
                  style={{
                    transform: "translate3d(0, 0, 0)",
                  }}
                  src={image}
                  width={720}
                  height={480}
                  sizes="(max-width: 640px) 100vw, (max-width: 1280px) 50vw, 350px"
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Transition appear show={isShowModal} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => {}}>
          {/* <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            > */}
          <div className="fixed inset-0 bg-white" />
          {/* </Transition.Child> */}

          <div className="fixed inset-0 overflow-y-auto">
            <div className="sticky z-10 top-0 xl:px-10 flex items-center justify-between bg-white">
              <button
                className="focus:outline-none focus:ring-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-neutral-100"
                onClick={onClose}
              >
                <ArrowSmallLeftIcon className="lg:w-6 lg:h-6 w-4 h-4" />
              </button>

              <LikeSaveBtns propID={propIdParam} />
            </div>
            <h2 className="text-lg sm:text-3xl lg:text-4xl font-semibold flex justify-center">
              Property Images
            </h2>
            <div className="flex min-h-full items-center justify-center sm:p-4 pt-0 text-center">
              {/* <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-5"
                  enterTo="opacity-100 translate-y-0"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-5"
                > */}
              <Dialog.Panel className="w-full max-w-screen-lg mx-auto transform p-4 pt-0 text-left transition-all ">
                {renderContent()}
              </Dialog.Panel>
              {/* </Transition.Child> */}
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ListingImageGallery;
