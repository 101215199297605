import { Tab } from "@headlessui/react";
import StayCard from "components/StayCard/StayCard";
import React, { useContext, useState, useEffect } from "react";
import CommonLayout from "./CommonLayout";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/userContext";

const AccountSavelists = () => {
  const authContext = useContext(AuthContext);
  const dataFavourite = authContext.favPropData;
  const [visibleCount, setVisibleCount] = useState(4);

  // Fetch favourite properties when component mounts
  useEffect(() => {
    authContext.getFavouriteProps();
  }, []);

  const handleShowMore = () => {
    // navigate("/");
    setVisibleCount(dataFavourite.length);
  };
  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Save lists</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="mt-8">
                {dataFavourite && dataFavourite.length > 0 ? (
                  <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {dataFavourite
                      .slice(0, visibleCount)
                      .map((property: any) => (
                        <StayCard
                          key={property && property?._id && property._id}
                          currentProperty={property}
                          data={property}
                          className="shadow-2xl"
                          size={"default"}
                        />
                      ))}
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-[252px] text-lg">
                    There Is No Favourite Property Available
                  </div>
                )}
                {dataFavourite && dataFavourite.length > visibleCount ? (
                  <div className="flex mt-11 justify-center items-center">
                    <button
                      onClick={handleShowMore}
                      className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50"
                    >
                      Show me more
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
