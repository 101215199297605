import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PropertyCardSkeleton = () => {
  return (
    <div className="lg:flex lg:flex-row flex flex-col sm:columns-2 gap-10">
      {[1, 2, 3, 4].map((index) => (
        <div key={index} className="lg:w-80 w-100 p-4 border rounded-lg shadow-lg">
          {/* Image Skeleton */}
          <Skeleton height={180} className="mb-4 rounded-lg" />

          {/* Type and Beds */}
          <Skeleton width={100} className="mb-2" />
          <Skeleton width={80} className="mb-2" />

          {/* Title */}
          <Skeleton width={`80%`} height={20} className="mb-2" />

          {/* Location */}
          <Skeleton width={`60%`} height={15} className="mb-2" />

          {/* Price and Rating */}
          <div className="flex justify-between items-center mt-4">
            <Skeleton width={50} height={20} />
            <Skeleton width={30} height={20} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PropertyCardSkeleton;
