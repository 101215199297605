import React, { useState, useEffect, FC, useContext } from "react";
import { StayDataType } from "data/types";
import homestays from "images/homestays.png";
import pgs from "images/pgs.jpeg";
import { AuthContext } from "context/userContext";
import { useNavigate } from "react-router-dom";

interface SectionGridFilterCardProps {
  allPropertyData?: StayDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  allPropertyData = [],

}) => {
  // authcontext
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const { setTypevalues } = authContext;


  const [currentPage, setCurrentPage] = useState<number>(1);
  const ItemsPerPage = 12;

  const scrollToView = () => {
    const PropertyTop = document.getElementById("PropertyTop");
    PropertyTop?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (Array.isArray(allPropertyData)) {
      setCurrentPage(1);
    }
  }, [allPropertyData]);

  const totalPages = Math.ceil(allPropertyData.length / ItemsPerPage);

  const startIndex = (currentPage - 1) * ItemsPerPage;
  const endIndex = Math.min(startIndex + ItemsPerPage, allPropertyData.length);

  const currentData = allPropertyData.slice(startIndex, endIndex);
  // const nextPage = () => {
  //   setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  //   scrollToView();
  // };
  // const prevPage = () => {
  //   setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  //   scrollToView();
  // };

  return (
    <div
      className={`nc-SectionGridFilterCard lg:mt-16`}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="mb-8 lg:mb-11 lg:mt-0 mt-8 flex justify-center ">
        <>
          <div className="images-container lg:w-3/12 flex gap-16 justify-between">
            {/* Homestays */}
            <div className="">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setTypevalues(["Homestays"]);
                  navigate("/search-result");
                }}
              >
                <img
                  className="rounded-full border-2 xl:max-w-32 xl:min-h-32 lg:max-w-28 lg:min-h-28 md:max-w-24 md:min-h-24 max-w-20 min-h-20 transition-opacity opacity-85 duration-300 hover:opacity-100 hover:shadow-black"
                  src={homestays}
                  alt="homestays"
                  width={128}
                  height={128}
                />

              </span>
              
              <p className="text-center">
                <a href="/search-result/?type=homestays">Homestays</a>
              </p>
            </div>

            {/* PGs */}
            <div>
              <span
                className="cursor-pointer"
                onClick={() => {
                  setTypevalues(["PGs"]);
                  navigate("/search-result");
                }}
              >
                <img
                  className="rounded-full border-2 xl:max-w-32 xl:min-h-32 lg:max-w-28 lg:min-h-28 md:max-w-24 md:min-h-24 max-w-20 min-h-20 transition-opacity opacity-85 duration-300 hover:opacity-100"
                  src={pgs}
                  alt="pgs"
                  width={128}
                  height={128}
                />
              </span>

              <p className="text-center">
                <a href="/search-result?type=pgs">PGs</a>
              </p>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
