import React, { FC } from "react";
import MainNav2 from "./MainNav2";
import { useLocation } from "react-router-dom";

export interface HeaderProps {
  navType?: "MainNav2";
  className?: string;
}

const Header: FC<HeaderProps> = ({ navType = "MainNav2", className = "" }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isDetailPage = location.pathname.includes("/detail");

  const headerStyle = isHomePage
    ? "nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg" // Example styles for home page
    : isDetailPage
    ? "nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg" // Example styles for detail page
    : "";

  const renderNav = () => {
        return <MainNav2 />;
  };

  return <div className={`${headerStyle} ${className}`}>{renderNav()}</div>;
};

export default Header;
