import React, { FC, ReactNode } from "react";
import imagePng from "images/hero-right2.png";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";
import { useLocation } from "react-router-dom";


export interface SectionHeroArchivePageProps {
  getPropertyFunc?: any;
  searchLocationValue?: any;
  setSearchLocationValue?: any;
  startDate?: any;
  endDate?:any,
  handleDateChange?:any;
  errorMsg?:any;
  setErrorMsg?:any;
  guests?: any;
  setGuests?: any;
  className?: string;
  listingType?: ReactNode;
  currentPage: "Stays" | "Experiences" | "Cars" | "Flights";
  currentTab: SearchTab;
  rightImage?: string;
  setShowSearchModal?:any;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  getPropertyFunc,
  searchLocationValue,
  setSearchLocationValue,
  startDate,
  endDate,
  handleDateChange,
  errorMsg,
  setErrorMsg,
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = imagePng,
  setShowSearchModal,
}) => {
  const location = useLocation();
  const isDetailPage = location.pathname.includes("/detail");

  return (
    <div
      className={`nc-SectionHeroArchivePage lg:flex lg:flex-col hidden relative lg:ml-[100px] ${className}`}
      data-nc-id="SectionHeroArchivePage"
    
    >
      <div className="hidden lg:flow-root w-full">
        <div className={`z-10 lg:-mt-10 xl:-mt-12 w-full ${isDetailPage ? "lg:h-screen" : ""}`}>
          <HeroSearchForm
            currentPage={currentPage}
            currentTab={currentTab}
            getPropertyData={getPropertyFunc}
            searchLocationValue={searchLocationValue}
            setSearchLocationValue={setSearchLocationValue}
            startDate={startDate}
            endDate={endDate}
            handleDateChange={handleDateChange}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            setShowSearchModal={setShowSearchModal}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
