import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CalendarSkeletonLoader = () => {
  return (
    <div className="p-4 border rounded-lg shadow-md max-w-sm">
   

      {/* Calendar Header */}
      <div className="flex justify-between items-center mb-2">
        <Skeleton width={30} height={24} />
        <Skeleton width={120} height={24} />
        <Skeleton width={30} height={24} />
      </div>

      {/* Calendar Grid */}
      <div className="grid grid-cols-7 gap-2">
        {Array(35)
          .fill(null)
          .map((_, index) => (
            <div key={index} className="flex justify-center">
              <Skeleton width={32} height={32} borderRadius="50%" />
            </div>
          ))}
      </div>

      {/* Legend */}
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center">
          <Skeleton width={16} height={16} className="mr-2" borderRadius="50%" />
          <Skeleton width={100} height={16} />
        </div>
        <div className="flex items-center">
          <Skeleton width={16} height={16} className="mr-2" borderRadius="50%" />
          <Skeleton width={100} height={16} />
        </div>
      </div>
    </div>
  );
};

export default CalendarSkeletonLoader;
